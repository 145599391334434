import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37468381"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "list-item-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_pull_refresh, {
        modelValue: _ctx.refreshing,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.refreshing) = $event)),
        onRefresh: _ctx.onRefresh
      }, {
        default: _withCtx(() => [
          _createVNode(_component_van_list, {
            loading: _ctx.loading,
            "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loading) = $event)),
            finished: _ctx.finished,
            "finished-text": "没有更多了",
            onLoad: _ctx.onLoad
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "list-item",
                  key: index,
                  onClick: ($event: any) => (_ctx.fGoDetail(item))
                }, [
                  _createElementVNode("img", {
                    class: "list-item-svg",
                    src: item.coverUrl
                  }, null, 8, _hoisted_4),
                  _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1)
                ], 8, _hoisted_3))
              }), 128))
            ]),
            _: 1
          }, 8, ["loading", "finished", "onLoad"])
        ]),
        _: 1
      }, 8, ["modelValue", "onRefresh"])
    ])
  ]))
}