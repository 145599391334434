
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/disciplinesafe.ts";
@Options({
  name: "Disciplinestudy",
})
export default class extends Vue {
  refreshing = false;
  loading = false;
  finished = false;
  list = [];
  page = {
    pageSize: 10,
    pageNum: 1,
  };
  onLoad() {
    setTimeout(async () => {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      const res: any = await api.list({
        projectId: this.$store.state.project.projectinfo.projectId,
      });
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.list = res.data;
      this.loading = false;
      this.finished = true;
    }, 1000);
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  fGoDetail(arg: any) {
    this.$router.push({
      name: "Disciplinestudydetail",
      params: { ...arg },
    });
  }
}
